// Portfoliobox config
// let bsPortfolioCollapse = null;
// let portfolioCollapse = null;

document.addEventListener('DOMContentLoaded', function () {

  var menuLinks = document.querySelectorAll("a[data-scroll-to]");

  menuLinks.forEach(el => {
    el.addEventListener('click', evt => {
      evt.preventDefault();
      var targetEl = document.querySelector(el.dataset.scrollTo);
      targetEl.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    })
  })
});

// Do after DOM and CSS has been loaded
window.onload = function() {

  // portfolioCollapse = document.getElementById('portfolio-box');
  // bsPortfolioCollapse = new bootstrap.Collapse(portfolioCollapse, {
  //   toggle: false
  // })

  document.querySelectorAll(".inner-nav>ul>li>a").forEach(element => {
    element.addEventListener('click', function() {
      document.querySelector('.inner-navigation').classList.remove('show')
    })
  })

  var offsetfooter = document.getElementById('footer').offsetHeight;
  document.body.style.marginBottom = offsetfooter - 5 + "px";

  document.querySelector('.hamburger').addEventListener('click', (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    document.querySelector('.hamburger').classList.toggle('is-active');
  })

  document.querySelector('.quick-contact').addEventListener('click', (evt) => {
    evt.preventDefault();
    evt.stopPropagation()
    document.querySelector('#quick-contact').classList.toggle('show-box');
  })

  document.querySelector('*').addEventListener('click', (evt) => {
    evt.stopPropagation()
    if (evt.target.classList.contains('box-close') || !document.querySelector('#quick-contact').contains(evt.target)) {
      document.querySelector('#quick-contact').classList.remove('show-box');
    }

    if (!document.querySelector('.inner-navigation').contains(evt.target)) {
      document.querySelector('.inner-navigation').classList.remove('show');
      document.querySelector('.hamburger').classList.remove('is-active');
    }

  })

} // end onload

window.addEventListener('resize', (e) => {
  var offsetfooter = document.getElementById('footer').offsetHeight;
  document.body.style.marginBottom = offsetfooter - 5 + "px";
})

window.addEventListener('orientationchange', function () {
  var originalBodyStyle = getComputedStyle(document.body).getPropertyValue('display');
  document.body.style.display='none';
  setTimeout(function () {
    document.body.style.display = originalBodyStyle;
  }, 30);

  var offsetfooter = document.getElementById('footer').offsetHeight;
  document.body.style.marginBottom = offsetfooter - 5 + "px";
});
